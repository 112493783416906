<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">购买人群</span>
    </div>
    <div class="content">
      <div v-for="(item, index) in data" :key="index" :class="index == 1 ? 'man' : 'woman'">
        <img src="../../assets/triangle.png" alt="" class="left-icon">
        <div class="item-content">
          <div class="item-text">{{ item.text }}</div>
          <div class="num-content">
            <span class="item-num">{{ item.num }}</span>
            <span class="item-unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div>
    <rightBottomChart />
  </div>
</template>

<script>
import rightBottomChart from '@/components/echart/page4/rightBottomChart2'
export default {
  components: {
    rightBottomChart
  },
  data() {
    return {
      data: [
        {
          text: '女性占比',
          num: 80,
          unit: '%'
        },
        {
          text: '男性占比',
          num: 20,
          unit: '%'
        }
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;
  // margin-bottom: 35px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/title.png');
    background-size: cover;
    display: flex;
    align-items: center;
    margin-bottom: 21px;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    margin-bottom: 29px;

    .woman {
      width: 230px;
      height: 76px;
      position: relative;
      margin-right: 11px;

      &::after {
        content: "";
        background-image: url('https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-bottom-right-icon1.png'); // 背景图片的路径
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -99;
      }
    }

    .man {
      width: 158px;
      height: 76px;
      position: relative;

      &::after {
        content: "";
        background-image: url('https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-bottom-right-icon2.png'); // 背景图片的路径
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -99;
      }
    }

    .left-icon {
      position: absolute;
      top: 5px;
      left: 5px;
    }

    .item-content {
      margin-top: 14px;
      margin-left: 11px;

      .item-text {
        font-size: 16px;
        font-weight: 500;
        color: rgba(208, 222, 238, 1);
        margin-bottom: 8px;
      }

      .num-content {
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 1.83px;
        color: rgba(255, 230, 217, 1);
        text-shadow: 0px 0px 8px  rgba(255, 119, 52, 0.5);
      }
    }
  }
}
</style>
