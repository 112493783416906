<template>
  <div class="bigbox">
    <Chart :cdata="cdata" />
    <div class="lable-item">
      <div class="item-title">
        <div class="title">认养项目</div>
        <div class="title">园区数量</div>
        <div class="title">占比</div>
      </div>
      <div class="item" v-for="(item, index) in lableData" :key="index">
        <div class="left">
          <div class="item-color" :style="{ 'background-color': item.color }"></div>
        <div class="item-text">{{ item.text }}</div>
        </div>
        <div class="item-num">{{ item.num }}</div>
        <div class="item-proportion">{{ item.proportion }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data() {
    return {
      cdata: {
        data: [
          { value: 550, name: '一米花田' },
          { value: 450, name: '一分菜园' },
          { value: 360, name: '一棵苹果树' },
          { value: 250, name: '一棵樱桃树' },
        ]
      },
      lableData: [
        {
          // , '#7aa1c9', '#bce9fd'
          color: '#00cdcb',
          text: '一米花田',
          num: '32',
          proportion: '61.5%'
        },
        {
          // , '', '#bce9fd'
          color: '#7aa1c9',
          text: '一分菜园',
          num: '12',
          proportion: '23.1%'
        },
        {
          // , '#7aa1c9', '#bce9fd'
          color: '#bce9fd',
          text: '一棵苹果树',
          num: '6',
          proportion: '11.5%'
        },
        {
          // , '#7aa1c9', '#bce9fd'
          color: '#dcb576',
          text: '一棵樱桃树',
          num: '3',
          proportion: '5.8%'
        }
      ]
    }
  },
  components: {
    Chart,
  }
}
</script>
<style lang="scss" scoped>
.bigbox {
  display: flex;
  align-items: center;

  .item-title {
    width: 175px;
    height: 15px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.86px;
    color: rgba(79, 240, 255, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    margin-left: 5px;
  }

  .item {
    width: 178px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;

    .left{
      display: flex;
      align-items: center;
    }
    .item-color {
      width: 8px;
      height: 8px;
      background: rgba(51, 204, 204, 1);
      margin-right: 10px;
    }

    .item-text {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.86px;
      // margin: 0 17px 0 10px;
    }

    .item-num {
      // margin-right: 18px;
    }

    .item-num,
    .item-proportion {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.75px;
    }
  }
}
</style>  