<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">园区供应</span>
    </div>
    <dv-scroll-board :config="config" class="scroll-board" style="width:100%;height:280px" />

  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        rowNum: 6,
        columnWidth: [160, 80,150],
        headerBGC: 'transparent',
        oddRowBGC: 'rgba(61, 85, 102, 0.16)',
        evenRowBGC: 'transparent',
        header: ['农场品名称', '价格','售出'],
        data: [
          ['西红柿', '5.80元/斤','300斤'],
          ['黄瓜', '3.20元/斤','150斤'],
          ['白菜', '1.08元/斤','100斤'],
          ['玉米', '3.00元/斤','100斤'],
          ['小米', '2.00元/斤','500斤'],
          ['小麦', '1.00元/斤','1000斤'],
          ['苹果', '2.16元/斤','158斤'],
          ['西瓜', '1.08元/斤','683斤'],
        ]
      }
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;
  // margin-bottom: 35px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/title.png');
    background-size: cover;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }
  ::v-deep .scroll-board .header .header-item {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    color: rgba(51, 204, 204, 1);
    text-align: center;
  }
  ::v-deep .scroll-board .rows .row-item .ceil{
    text-align: center;
    // text-align: center;
  }
}
</style>
