<template>
    <div id="index" ref="appRef">
        <div class="bg Anim">
            <div class="host-body">
                <!-- 标题行 -->
                <div class="title">
                    <img src="../assets/logo.png" alt="" class="big-title-img">
                    <div class="time">
                        <span class="year">{{ dateYear }}</span>
                        <span class="week">{{ dateWeek }}</span>
                        <span class="day">{{ dateDay }}</span>
                    </div>
                </div>
                <!-- 内容 -->
                <div class="content">
                    <!-- 左边 -->
                    <div class="left">
                        <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/pengpeng-logo.png" alt="" class="icon1">
                        <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/pengpeng.png" alt="" class="icon2">
                        <router-link to="/">
                            <button class="button1">
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-left-icon2.png" alt=""
                                    class="icon3">
                                <span class="button1-text">返回首页</span>
                            </button>
                        </router-link>
                        <router-link to="/page3">
                            <button class="button2">
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon3.png" alt=""
                                    class="button-img">
                                <div class="button-text">数字农管</div>
                            </button>
                        </router-link>
                        <router-link to="/page2">
                            <button class="button2">
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon1.png" alt=""
                                    class="button-img">
                                <div class="button-text">数字农人</div>
                            </button>
                        </router-link>
                        <router-link to="/page4">
                            <button class="button2">
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon2.png" alt=""
                                    class="button-img">
                                <div class="button-text active">数字营销</div>
                            </button>
                        </router-link>
                    </div>
                    <!-- 右边 -->
                    <div class="right">
                        <div class="right-top">
                            <div class="top-left">
                                <div class="item-box">
                                    <img src="../assets/page4-left-icon3.png" alt="" class="icon4">
                                    <div class="item">
                                        <div class="item-text">CO2浓/PPM</div>
                                        <div class="item-num">880</div>
                                    </div>
                                    <div class="line"></div>
                                    <div class="item">
                                        <div class="item-text">超过/PPM</div>
                                        <div class="item-num">180</div>
                                    </div>
                                </div>
                            </div>
                            <div class="top-right">
                                <div class="item">
                                    <img src="../assets/page4-left-icon4.png" alt="">
                                    <div class="item-content">
                                        <div class="item-text">温度</div>
                                        <div class="item-num">
                                            <span class="num">28.4</span>
                                            <span checked="unit">℃</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="item">
                                    <img src="../assets/page4-left-icon5.png" alt="">
                                    <div class="item-content">
                                        <div class="item-text">湿度%</div>
                                        <div class="item-num">
                                            <span class="num">59.10</span>
                                            <span checked="unit">%RH</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="item">
                                    <img src="../assets/page4-left-icon6.png" alt="">
                                    <div class="item-content">
                                        <div class="item-text">光照强度</div>
                                        <div class="item-num">
                                            <span class="num">1548</span>
                                            <span checked="unit">Lux</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="item">
                                    <img src="../assets/page4-left-icon7.png" alt="">
                                    <div class="item-content">
                                        <div class="item-text">CO2浓度</div>
                                        <div class="item-num">
                                            <span class="num num-color">880</span>
                                            <span checked="unit">PPM</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="item">
                                    <img src="../assets/page4-left-icon8.png" alt="">
                                    <div class="item-content">
                                        <div class="item-text">土壤EC值</div>
                                        <div class="item-num">
                                            <span class="num">1092</span>
                                            <span checked="unit">us/cm</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="item">
                                    <img src="../assets/page4-left-icon9.png" alt="">
                                    <div class="item-content">
                                        <div class="item-text">土壤PH值</div>
                                        <div class="item-num">
                                            <span class="num">7.99</span>
                                            <span checked="unit"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="item">
                                    <img src="../assets/page4-left-icon11.png" alt="">
                                    <div class="item-content">
                                        <div class="item-text">土壤氮</div>
                                        <div class="item-num">
                                            <span class="num">83</span>
                                            <span checked="unit">mg/KG</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="item">
                                    <img src="../assets/page4-left-icon12.png" alt="">
                                    <div class="item-content">
                                        <div class="item-text">土壤磷</div>
                                        <div class="item-num">
                                            <span class="num">118</span>
                                            <span checked="unit">mg/KG</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="item">
                                    <img src="../assets/page4-left-icon13.png" alt="">
                                    <div class="item-content">
                                        <div class="item-text">土壤钾</div>
                                        <div class="item-num">
                                            <span class="num">220</span>
                                            <span checked="unit">mg/KG</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-center" :class="inAnimation ? 'Anim' : ''" @click="changeMsg">
                            <div class="right-center-left">
                                <!-- <div class="outdoor-temperature">
                                    <div class="outdoor-title">室外环境</div>
                                    <div class="item-box">
                                        <div class="item" v-for="(item, index) in outdoorTemperatureData" :key="index">
                                            <span class="item-title">{{ item.text }}</span>
                                            <span class="item-content">{{ item.content }}</span>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="cameraBox animate__animated animate__fadeInLeft">
                                    <!-- <img class="cameraIcon"
                                        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon12.png" alt=""> -->
                                        <div class="camera">
                                        <div class="top-text">摄像机0001</div>
                                        <video src="https://nonglian.oss-cn-shanghai.aliyuncs.com/camera2.mp4" class="camera-video" autoplay muted loop></video>
                                    </div>
                                    <div class="line1"></div>
                                    <div class="line2"></div>
                                </div>
                                <!-- <div class="deviceList-box">
                                    <img class="list"
                                        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon1.png" alt="">
                                    <img class="list"
                                        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon2.png" alt="">
                                    <img class="list"
                                        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon3.png" alt="">
                                    <img class="list"
                                        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon4.png" alt="">
                                    <img class="list"
                                        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon5.png" alt="">
                                    <img class="list"
                                        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon6.png" alt="">
                                    <img class="list"
                                        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon7.png" alt="">
                                    <img class="list"
                                        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon8.png" alt="">
                                    <img class="list"
                                        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/right-center-icon9.png" alt="">
                                </div> -->
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-icon1.png" alt="" class="right-center-left-icon1">
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-icon2.png" alt="" class="right-center-left-icon2">
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-icon3.png" alt="" class="right-center-left-icon3">
                                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page4-icon4.png" alt="" class="right-center-left-icon4">
                            </div>
                            <div class="right-center-right">
                                <centerLeft1 />
                                <centerLeft2/>
                                <centerLeft3 />
                                <!-- <centerLeft1 /> -->
                                <!-- <centerLeft2 />
                                    <centerLeft3 /> -->
                            </div>
                        </div>
                        <div class="right-bottom">
                            <centerLeft4 />
                            <centerLeft5 />
                            <centerLeft6 />
                            <!-- <centerLeft1 /> -->
                            <!-- <centerLeft2 /> -->
                            <!-- <centerLeft3 /> -->
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="bottom-left">
                        <dv-decoration-2 class="decoration-two1" />
                        <img src="../assets/bottom-icon2.png" alt="" class="bottom-img">
                    </div>
                    <div class="bottom-center"></div>
                    <div class="bottom-right">
                        <img src="../assets/bottom-icon1.png" alt="" class="bottom-img">
                        <dv-decoration-2 class="decoration-two2" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import centerLeft1 from './page4/centerLeft1'
import centerLeft2 from './page4/centerLeft2'
import centerLeft3 from './page4/centerLeft3'
import centerLeft4 from './page4/centerLeft4'
import centerLeft5 from './page4/centerLeft5'
import centerLeft6 from './page4/centerLeft6'

export default {
    mixins: [drawMixin],
    data() {
        return {
            inAnimation: false,
            timing: null,
            dateDay: null,
            dateYear: null,
            dateWeek: null,
            weekday: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
            outdoorTemperatureData: [
                {
                    text: '风向',
                    content: '西北'
                },
                {
                    text: '风速',
                    content: '5.9m/s'
                },
                {
                    text: '温度',
                    content: '37℃'
                },
                {
                    text: '湿度',
                    content: '45%'
                },
                {
                    text: '雨量',
                    content: '60mm'
                },
                {
                    text: '气压',
                    content: '1.01kpa'
                },
            ],
        }
    },
    components: {
        centerLeft1,
        centerLeft2,
        centerLeft3,
        centerLeft4,
        centerLeft5,
        centerLeft6
        //   center,
        //   bottomLeft,
        //   bottomRight
    },
    mounted() {
        this.timeFn()
    },
    beforeDestroy() {
        clearInterval(this.timing)
    },
    methods: {
        changeMsg() {
            // this.inAnimation = !this.inAnimation;
            // setTimeout(() => {
            //     this.$router.push('/page2')
            // }, 500)
        },
        timeFn() {
            this.timing = setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy.MM.dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        },
    }
}
</script>
  
<style lang="scss" scoped>
@import '../assets/scss/page4.scss';
</style>